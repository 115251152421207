import React from 'react';
import Navbar from '../Components/DefNavbar'; 
import Sidebar from '../Components/Sidebar';
import TopicsGraph from '../Components/TopicsGraph';
import './Explore.css';

const Explore = () => {
  const toggleSidebar = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const sidebar = document.querySelector('.sidebar-container');
    if (sidebar) {
      sidebar.classList.toggle('show');
      document.dispatchEvent(new Event('sidebarToggle'));
    }
  };

  return (
    <div className="explore-container">
      <div className="explore-navbar-container">
        <div><Navbar /></div>
      </div>
      <div className="info-button menu-button">
        <button className="info-toggle" onClick={(e) => toggleSidebar(e)}>
          <span className="material-icons">menu</span>
        </button>
      </div>
      <Sidebar />
      <div className="main-content">
        <TopicsGraph />
      </div>
    </div>
  );
};

export default Explore;