import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../../Components/Sidebar';
import './AtomsSandbox.css';

// First, add the complete standardNeutrons data
const standardNeutrons = {
    1: 0,    // Hydrogen
    2: 2,    // Helium
    3: 4,    // Lithium
    4: 5,    // Beryllium
    5: 6,    // Boron
    6: 6,    // Carbon
    7: 7,    // Nitrogen
    8: 8,    // Oxygen
    9: 10,   // Fluorine
    10: 10,  // Neon
    11: 12,  // Sodium
    12: 12,  // Magnesium
    13: 14,  // Aluminum
    14: 14,  // Silicon
    15: 16,  // Phosphorus
    16: 16,  // Sulfur
    17: 18,  // Chlorine
    18: 22,  // Argon
    19: 20,  // Potassium
    20: 20,  // Calcium
    21: 24,  // Scandium
    22: 26,  // Titanium
    23: 28,  // Vanadium
    24: 28,  // Chromium
    25: 30,  // Manganese
    26: 30,  // Iron
    27: 32,  // Cobalt
    28: 31,  // Nickel
    29: 35,  // Copper
    30: 35,  // Zinc
    31: 39,  // Gallium
    32: 41,  // Germanium
    33: 42,  // Arsenic
    34: 45,  // Selenium
    35: 45,  // Bromine
    36: 48,  // Krypton
    37: 48,  // Rubidium
    38: 50,  // Strontium
    39: 50,  // Yttrium
    40: 50,  // Zirconium
    41: 52,  // Niobium
    42: 50,  // Molybdenum
    43: 55,  // Technetium
    44: 52,  // Ruthenium
    45: 58,  // Rhodium
    46: 56,  // Palladium
    47: 60,  // Silver
    48: 62,  // Cadmium
    49: 66,  // Indium
    50: 66,  // Tin
    51: 70,  // Antimony
    52: 76,  // Tellurium
    53: 74,  // Iodine
    54: 78,  // Xenon
    55: 78,  // Cesium
    56: 80,  // Barium
    57: 82,  // Lanthanum
    58: 82,  // Cerium
    59: 83,  // Praseodymium
    60: 84,  // Neodymium
    61: 84,  // Promethium
    62: 88,  // Samarium
    63: 89,  // Europium
    64: 93,  // Gadolinium
    65: 94,  // Terbium
    66: 97,  // Dysprosium
    67: 98,  // Holmium
    68: 101, // Erbium
    69: 103, // Thulium
    70: 106, // Ytterbium
    71: 104, // Lutetium
    72: 106, // Hafnium
    73: 108, // Tantalum
    74: 110, // Tungsten
    75: 111, // Rhenium
    76: 114, // Osmium
    77: 115, // Iridium
    78: 117, // Platinum
    79: 118, // Gold
    80: 121, // Mercury
    81: 123, // Thallium
    82: 125, // Lead
    83: 126, // Bismuth
    84: 125, // Polonium
    85: 125, // Astatine
    86: 136, // Radon
    87: 136, // Francium
    88: 138, // Radium
    89: 138, // Actinium
    90: 142, // Thorium
    91: 140, // Protactinium
    92: 146, // Uranium
    93: 144, // Neptunium
    94: 145, // Plutonium
    95: 146, // Americium
    96: 151, // Curium
    97: 150, // Berkelium
    98: 153, // Californium
    99: 153, // Einsteinium
    100: 157, // Fermium
    101: 157, // Mendelevium
    102: 157, // Nobelium
    103: 159, // Lawerencium
    104: 163, // Rutherfordium
    105: 163, // Dubnium
    106: 163, // Seaborgium
    107: 165, // Bohrium
    108: 169, // Hassium
    109: 169, // Meitnerium
    110: 173, // Darmstadtium
    111: 173, // Roentgenium
    112: 173, // Copernicium
    113: 175, // Nihonium
    114: 175, // Flerovium
    115: 173, // Moscovium
    116: 177, // Livermorium
    117: 177, // Tennessine
    118: 176  // Oganesson
};

// Move the entire ParticleSimulator class here, outside the component
class ParticleSimulator {
    constructor(canvas) {
        this.canvas = canvas;
        this.ctx = canvas.getContext('2d');
        this.particles = {
            protons: 1,
            neutrons: 1,
            electrons: 1
        };
        this.elementTitle = document.getElementById('elementTitle');

        this.animationFrame = null;
        this.lastTimestamp = 0;
        this.baseOrbitalSpeed = 0.005;
        this.shellAngles = new Array(5).fill(0);
        this.nucleusParticles = [];

        this.scale = 1;
        this.offset = { x: 0, y: 0 };
        this.isDragging = false;
        this.lastMousePos = { x: 0, y: 0 };
        this.minScale = 0.1;
        this.maxScale = 5;

        this.centerPos = {
            x: window.innerWidth / 2,
            y: window.innerHeight / 2
        };

        // Add standard neutron counts for elements
        this.standardNeutrons = standardNeutrons;

        // Bind methods that are used as callbacks
        this.getDecayModes = this.getDecayModes.bind(this);
        this.calculateStability = this.calculateStability.bind(this);
        this.animate = this.animate.bind(this);
        this.draw = this.draw.bind(this);
        this.updateDisplay = this.updateDisplay.bind(this);
        this.updateInfoPanel = this.updateInfoPanel.bind(this);

        // Copy all the methods and properties from Moleculesscript.js
        // ... [I'll continue with the rest of the implementation in subsequent messages]

        this.onParticlesUpdate = null;
    }

    // Canvas and initialization methods
    initializeCanvas() {
        this.resizeCanvas();
        window.addEventListener('resize', () => this.resizeCanvas());
    }

    resizeCanvas() {
        this.canvas.width = window.innerWidth;
        this.canvas.height = window.innerHeight;
        this.centerPos = {
            x: window.innerWidth / 2,
            y: window.innerHeight / 2
        };
        this.draw();
    }

    // Drawing methods
    draw() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.save();
        this.ctx.translate(this.offset.x, this.offset.y);
        this.ctx.scale(this.scale, this.scale);

        const centerX = this.canvas.width / 2 / this.scale;
        const centerY = this.canvas.height / 2 / this.scale;

        this.drawNucleus(centerX, centerY);
        this.drawElectrons(centerX, centerY);

        this.ctx.restore();
    }

    // [Copy all the drawing methods from Moleculesscript.js]
    drawNucleus(centerX, centerY) {
        const totalParticles = this.particles.protons + this.particles.neutrons;
        if (totalParticles === 0) return;

        // Only generate new particle arrangement if number of particles changed
        if (this.nucleusParticles.length !== totalParticles) {
            // Create array of all particles with their types
            this.nucleusParticles = [
                ...Array(this.particles.protons).fill('proton'),
                ...Array(this.particles.neutrons).fill('neutron')
            ];

            // Shuffle array once when creating new arrangement
            for (let i = this.nucleusParticles.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [this.nucleusParticles[i], this.nucleusParticles[j]] =
                    [this.nucleusParticles[j], this.nucleusParticles[i]];
            }
        }

        const particleRadius = 10;
        const spacing = particleRadius * 0.4;
        const minRadius = particleRadius + spacing;

        // Calculate shells
        let shellRadius = minRadius;
        let remainingParticles = totalParticles;
        const shells = [];

        while (remainingParticles > 0) {
            const circumference = 2 * Math.PI * shellRadius;
            const particlesInShell = Math.floor(circumference / (2 * (particleRadius + spacing)));
            const shellCount = Math.min(particlesInShell, remainingParticles);

            shells.push({
                radius: shellRadius,
                count: shellCount
            });

            remainingParticles -= shellCount;
            shellRadius += 2 * (particleRadius + spacing);
        }

        // Draw particles shell by shell
        let particleIndex = 0;
        shells.forEach(shell => {
            const angleStep = (2 * Math.PI) / shell.count;

            for (let i = 0; i < shell.count; i++) {
                const angle = i * angleStep;
                // Add slight random offset for more natural look
                const randomOffset = {
                    x: (Math.random() - 0.5) * spacing,
                    y: (Math.random() - 0.5) * spacing
                };

                const x = centerX + Math.cos(angle) * shell.radius + randomOffset.x;
                const y = centerY + Math.sin(angle) * shell.radius + randomOffset.y;

                const particleType = this.nucleusParticles[particleIndex];

                // Draw particle with gradient effect
                this.ctx.beginPath();
                this.ctx.arc(x, y, particleRadius, 0, 2 * Math.PI);

                const gradient = this.ctx.createRadialGradient(
                    x, y, 0,
                    x, y, particleRadius
                );
                gradient.addColorStop(0, particleType === 'proton' ? '#ff6666' : '#6666ff');
                gradient.addColorStop(1, particleType === 'proton' ? '#cc0000' : '#0000cc');
                this.ctx.fillStyle = gradient;

                // Add shadow for 3D effect
                this.ctx.shadowColor = 'rgba(0, 0, 0, 0.2)';
                this.ctx.shadowBlur = 5;
                this.ctx.shadowOffsetX = 2;
                this.ctx.shadowOffsetY = 2;

                this.ctx.fill();

                particleIndex++;
            }
        });

        // Reset shadow effect
        this.ctx.shadowColor = 'transparent';
        this.ctx.shadowBlur = 0;
        this.ctx.shadowOffsetX = 0;
        this.ctx.shadowOffsetY = 0;
    }

    drawElectrons(centerX, centerY) {
        if (this.particles.electrons === 0) return;

        const nucleusRadius = this.calculateNucleusRadius();
        const baseShellSpacing = 80;

        const shellConfig = [
            { maxElectrons: 2, radius: nucleusRadius + baseShellSpacing },
            { maxElectrons: 8, radius: nucleusRadius + baseShellSpacing * 2 },
            { maxElectrons: 18, radius: nucleusRadius + baseShellSpacing * 3 },
            { maxElectrons: 32, radius: nucleusRadius + baseShellSpacing * 4 },
            { maxElectrons: 50, radius: nucleusRadius + baseShellSpacing * 5 }
        ];

        const electronRadius = 5;
        let remainingElectrons = this.particles.electrons;

        // Draw electron shells
        shellConfig.forEach((shell, shellIndex) => {
            if (remainingElectrons <= 0) return;

            const electronsInShell = Math.min(shell.maxElectrons, remainingElectrons);
            remainingElectrons -= electronsInShell;

            // Draw orbit path
            this.ctx.beginPath();
            this.ctx.arc(centerX, centerY, shell.radius, 0, 2 * Math.PI);
            this.ctx.strokeStyle = 'rgba(128, 128, 128, 0.2)';
            this.ctx.stroke();

            // Draw electrons with current shell angle offset
            const baseAngleStep = (2 * Math.PI) / electronsInShell;

            for (let i = 0; i < electronsInShell; i++) {
                const angle = baseAngleStep * i + this.shellAngles[shellIndex];
                const x = centerX + Math.cos(angle) * shell.radius;
                const y = centerY + Math.sin(angle) * shell.radius;

                // Draw electron with gradient effect
                this.ctx.beginPath();
                this.ctx.arc(x, y, electronRadius, 0, 2 * Math.PI);

                const gradient = this.ctx.createRadialGradient(
                    x, y, 0,
                    x, y, electronRadius
                );
                gradient.addColorStop(0, '#90EE90');
                gradient.addColorStop(1, '#006400');
                this.ctx.fillStyle = gradient;

                // Add glow effect
                this.ctx.shadowColor = 'rgba(0, 255, 0, 0.5)';
                this.ctx.shadowBlur = 5;
                this.ctx.fill();
            }
        });

        // Reset shadow effect
        this.ctx.shadowColor = 'transparent';
        this.ctx.shadowBlur = 0;
    }

    calculateNucleusRadius() {
        const totalParticles = this.particles.protons + this.particles.neutrons;
        if (totalParticles === 0) return 0;

        const particleRadius = 10;
        const spacing = particleRadius * 0.4;
        const minRadius = particleRadius + spacing;

        let shellRadius = minRadius;
        let remainingParticles = totalParticles;

        while (remainingParticles > 0) {
            const circumference = 2 * Math.PI * shellRadius;
            const particlesInShell = Math.floor(circumference / (2 * (particleRadius + spacing)));
            remainingParticles -= particlesInShell;
            if (remainingParticles > 0) {
                shellRadius += 2 * (particleRadius + spacing);
            }
        }

        return shellRadius + particleRadius;
    }

    // Animation methods
    animate(timestamp) {
        const delta = timestamp - this.lastTimestamp;
        this.lastTimestamp = timestamp;

        this.shellAngles = this.shellAngles.map((angle, index) =>
            angle + (this.baseOrbitalSpeed * delta) / (index + 1)
        );

        this.draw();
        this.animationFrame = requestAnimationFrame(this.animate.bind(this));
    }

    cleanup() {
        if (this.animationFrame) {
            cancelAnimationFrame(this.animationFrame);
        }
    }

    // Event handlers
    setupEventListeners() {
        const LIMITS = {
            protons: 118,    // Heaviest known element (Oganesson)
            neutrons: 177,   // Maximum neutrons in known elements
            electrons: 118   // Maximum electrons (matches max protons)
        };

        // Update mouse wheel event for zoom
        this.canvas.addEventListener('wheel', (e) => {
            e.preventDefault();

            // Get mouse position in screen coordinates
            const mouseX = e.clientX;
            const mouseY = e.clientY;

            // Calculate new scale
            const zoomFactor = e.deltaY > 0 ? 0.9 : 1.1;
            const newScale = Math.min(Math.max(this.scale * zoomFactor, this.minScale), this.maxScale);

            if (this.scale !== newScale) {
                // Calculate mouse position relative to canvas center
                const canvasCenterX = this.canvas.width / 2;
                const canvasCenterY = this.canvas.height / 2;

                // Calculate the point we're zooming to in the current scaled/translated space
                const pointX = (mouseX - this.offset.x) / this.scale;
                const pointY = (mouseY - this.offset.y) / this.scale;

                // Update scale
                this.scale = newScale;

                // Calculate new offset to maintain the point position
                this.offset.x = mouseX - pointX * this.scale;
                this.offset.y = mouseY - pointY * this.scale;

                this.draw();
            }
        });

        // Update mouse events for panning
        this.canvas.addEventListener('mousedown', (e) => {
            if (e.button === 0) {
                this.isDragging = true;
                this.lastMousePos = {
                    x: e.clientX,
                    y: e.clientY
                };
                this.canvas.style.cursor = 'grabbing';
            }
        });

        window.addEventListener('mousemove', (e) => {
            if (!this.isDragging) return;

            // Calculate the movement delta
            const deltaX = e.clientX - this.lastMousePos.x;
            const deltaY = e.clientY - this.lastMousePos.y;

            // Move in the opposite direction of the drag
            this.offset.x += deltaX;
            this.offset.y += deltaY;

            // Update the last mouse position
            this.lastMousePos = {
                x: e.clientX,
                y: e.clientY
            };

            // Request a new frame
            this.draw();
        });

        window.addEventListener('mouseup', () => {
            if (this.isDragging) {
                this.isDragging = false;
                this.canvas.style.cursor = 'grab';
            }
        });

        // Prevent context menu on right click
        this.canvas.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });

        // Handle mouse leaving the window
        window.addEventListener('mouseleave', () => {
            if (this.isDragging) {
                this.isDragging = false;
                this.canvas.style.cursor = 'grab';
            }
        });
    }

    // Element data and calculations
    setupDropdown() {
        const dropdownButton = document.querySelector('.dropdown-button');
        const dropdownContent = document.querySelector('.dropdown-content');
        const dropdownList = document.querySelector('.dropdown-list');
        const searchInput = document.getElementById('elementSearch');

        // Get all input elements first
        const protonInput = document.getElementById('protonInput');
        const neutronInput = document.getElementById('neutronInput');
        const electronInput = document.getElementById('electronInput');

        // Use the complete elements array
        const elements = [
            'Hydrogen', 'Helium', 'Lithium', 'Beryllium', 'Boron', 'Carbon', 'Nitrogen', 'Oxygen',
            'Fluorine', 'Neon', 'Sodium', 'Magnesium', 'Aluminum', 'Silicon', 'Phosphorus', 'Sulfur',
            'Chlorine', 'Argon', 'Potassium', 'Calcium', 'Scandium', 'Titanium', 'Vanadium', 'Chromium',
            'Manganese', 'Iron', 'Cobalt', 'Nickel', 'Copper', 'Zinc', 'Gallium', 'Germanium', 'Arsenic',
            'Selenium', 'Bromine', 'Krypton', 'Rubidium', 'Strontium', 'Yttrium', 'Zirconium', 'Niobium',
            'Molybdenum', 'Technetium', 'Ruthenium', 'Rhodium', 'Palladium', 'Silver', 'Cadmium', 'Indium',
            'Tin', 'Antimony', 'Tellurium', 'Iodine', 'Xenon', 'Cesium', 'Barium', 'Lanthanum', 'Cerium',
            'Praseodymium', 'Neodymium', 'Promethium', 'Samarium', 'Europium', 'Gadolinium', 'Terbium',
            'Dysprosium', 'Holmium', 'Erbium', 'Thulium', 'Ytterbium', 'Lutetium', 'Hafnium', 'Tantalum',
            'Tungsten', 'Rhenium', 'Osmium', 'Iridium', 'Platinum', 'Gold', 'Mercury', 'Thallium', 'Lead',
            'Bismuth', 'Polonium', 'Astatine', 'Radon', 'Francium', 'Radium', 'Actinium', 'Thorium',
            'Protactinium', 'Uranium', 'Neptunium', 'Plutonium', 'Americium', 'Curium', 'Berkelium',
            'Californium', 'Einsteinium', 'Fermium', 'Mendelevium', 'Nobelium', 'Lawrencium',
            'Rutherfordium', 'Dubnium', 'Seaborgium', 'Bohrium', 'Hassium', 'Meitnerium', 'Darmstadtium',
            'Roentgenium', 'Copernicium', 'Nihonium', 'Flerovium', 'Moscovium', 'Livermorium',
            'Tennessine', 'Oganesson'
        ];

        // Create dropdown items
        const createDropdownItems = (filter = '') => {
            dropdownList.innerHTML = '';
            const filteredElements = elements
                .filter(element => element.toLowerCase().includes(filter.toLowerCase()));

            filteredElements.forEach((element) => {
                const item = document.createElement('div');
                item.className = 'dropdown-item';
                item.textContent = element;

                item.addEventListener('click', () => {
                    const protonCount = elements.indexOf(element) + 1;
                    const neutronCount = this.standardNeutrons[protonCount] || 0;

                    // Update particle counts
                    this.particles = {
                        protons: protonCount,
                        neutrons: neutronCount,
                        electrons: protonCount // Neutral atom
                    };

                    // Notify React component of the update
                    if (this.onParticlesUpdate) {
                        this.onParticlesUpdate(this.particles);
                    }

                    // Update display and close dropdown
                    this.updateDisplay();
                    this.draw();
                    dropdownContent.classList.remove('show');
                });

                dropdownList.appendChild(item);
            });
        };

        // Toggle dropdown
        dropdownButton.addEventListener('click', (e) => {
            e.stopPropagation();
            dropdownContent.classList.toggle('show');
            if (dropdownContent.classList.contains('show')) {
                searchInput.focus();
                createDropdownItems();
            }
        });

        // Handle search
        searchInput.addEventListener('input', (e) => {
            createDropdownItems(e.target.value);
        });

        // Close dropdown when clicking outside
        document.addEventListener('click', (e) => {
            if (!dropdownContent.contains(e.target) && !dropdownButton.contains(e.target)) {
                dropdownContent.classList.remove('show');
            }
        });
    }

    updateDisplay() {
        if (this.particles.protons === 0) {
            this.elementTitle.textContent = '-';
            return;
        }

        const elements = [
            'Hydrogen', 'Helium', 'Lithium', 'Beryllium', 'Boron', 'Carbon', 'Nitrogen', 'Oxygen',
            'Fluorine', 'Neon', 'Sodium', 'Magnesium', 'Aluminum', 'Silicon', 'Phosphorus', 'Sulfur',
            'Chlorine', 'Argon', 'Potassium', 'Calcium', 'Scandium', 'Titanium', 'Vanadium', 'Chromium',
            'Manganese', 'Iron', 'Cobalt', 'Nickel', 'Copper', 'Zinc', 'Gallium', 'Germanium', 'Arsenic',
            'Selenium', 'Bromine', 'Krypton', 'Rubidium', 'Strontium', 'Yttrium', 'Zirconium', 'Niobium',
            'Molybdenum', 'Technetium', 'Ruthenium', 'Rhodium', 'Palladium', 'Silver', 'Cadmium', 'Indium',
            'Tin', 'Antimony', 'Tellurium', 'Iodine', 'Xenon', 'Cesium', 'Barium', 'Lanthanum', 'Cerium',
            'Praseodymium', 'Neodymium', 'Promethium', 'Samarium', 'Europium', 'Gadolinium', 'Terbium',
            'Dysprosium', 'Holmium', 'Erbium', 'Thulium', 'Ytterbium', 'Lutetium', 'Hafnium', 'Tantalum',
            'Tungsten', 'Rhenium', 'Osmium', 'Iridium', 'Platinum', 'Gold', 'Mercury', 'Thallium', 'Lead',
            'Bismuth', 'Polonium', 'Astatine', 'Radon', 'Francium', 'Radium', 'Actinium', 'Thorium',
            'Protactinium', 'Uranium', 'Neptunium', 'Plutonium', 'Americium', 'Curium', 'Berkelium',
            'Californium', 'Einsteinium', 'Fermium', 'Mendelevium', 'Nobelium', 'Lawrencium',
            'Rutherfordium', 'Dubnium', 'Seaborgium', 'Bohrium', 'Hassium', 'Meitnerium', 'Darmstadtium',
            'Roentgenium', 'Copernicium', 'Nihonium', 'Flerovium', 'Moscovium', 'Livermorium',
            'Tennessine', 'Oganesson'
        ];

        // Get element name
        const elementName = elements[this.particles.protons - 1] || 'Unknown';

        // Calculate mass number (protons + neutrons)
        const massNumber = this.particles.protons + this.particles.neutrons;

        // Get standard number of neutrons for this element
        const standardNeutrons = this.standardNeutrons[this.particles.protons] || 0;
        const standardMass = this.particles.protons + standardNeutrons;

        // Calculate charge
        const charge = this.particles.protons - this.particles.electrons;

        // Create the charge notation with superscript
        let chargeNotation = '';
        if (charge !== 0) {
            const superscriptCharge = Math.abs(charge);
            const superscriptDigits = superscriptCharge > 1
                ? superscriptCharge.toString()
                    .split('')
                    .map(digit => '⁰¹²³⁴⁵⁶⁷⁸⁹'[parseInt(digit)])
                    .join('')
                : '';
            chargeNotation = charge > 0 ? `${superscriptDigits}⁺` : `${superscriptDigits}⁻`;
        }

        // Only show mass number if it differs from the standard
        const massNotation = massNumber !== standardMass ? `-${massNumber}` : '';

        // Construct the full element name with optional mass number and charge
        this.elementTitle.textContent = `${elementName}${massNotation}${chargeNotation}`;

        this.updateInfoPanel();
    }

    setupInfoPanel() {
        const infoButton = document.querySelector('.info-button .info-toggle');
        const infoPanel = document.querySelector('.info-panel');
        const closeInfo = document.querySelector('.close-info');

        infoButton.addEventListener('click', () => {
            infoPanel.classList.add('show');
            this.updateInfoPanel();
        });

        closeInfo.addEventListener('click', () => {
            infoPanel.classList.remove('show');
        });
    }

    updateInfoPanel() {
        const protons = this.particles.protons;
        const neutrons = this.particles.neutrons;
        const electrons = this.particles.electrons;

        // Get abundance, half-life, and decay mode info
        const { abundance, halfLife, decayMode } = this.getIsotopeInfo(protons, neutrons);

        document.getElementById('infoAbundance').textContent = abundance;

        // Show/hide and update half-life and decay mode sections
        const halfLifeSection = document.getElementById('halfLifeSection');
        const halfLifeElement = document.getElementById('infoHalfLife');
        const decayModeSection = document.getElementById('decayModeSection');
        const decayModeElement = document.getElementById('infoDecayModes');

        if (halfLife) {
            halfLifeSection.style.display = 'block';
            halfLifeElement.textContent = halfLife;
            if (decayMode) {
                decayModeSection.style.display = 'block';
                decayModeElement.textContent = decayMode;
            } else {
                decayModeSection.style.display = 'none';
            }
        } else {
            halfLifeSection.style.display = 'none';
            decayModeSection.style.display = 'none';
        }

        // Update other properties
        document.getElementById('infoStability').textContent = this.calculateStability(protons, neutrons);
        document.getElementById('infoBindingEnergy').textContent = this.calculateBindingEnergy(protons, neutrons);
        document.getElementById('infoMagnetic').textContent = this.getMagneticProperties(protons, electrons);
        document.getElementById('infoReactivity').textContent = this.getReactivity(protons, electrons);
        document.getElementById('infoNuclearSpin').textContent = this.getNuclearSpin(protons, neutrons);
        document.getElementById('infoApplications').textContent = this.getIsotopeApplications(protons, neutrons);
        document.getElementById('infoIonization').textContent = this.getIonizationEnergy(protons, electrons);
        document.getElementById('infoSpectral').textContent = this.getSpectralLines(protons, electrons);
    }

    getIsotopeInfo(protons, neutrons) {
        if (protons === 0 || neutrons === 0) {
            return {
                abundance: "No nucleus",
                halfLife: null,
                decayMode: null
            };
        }

        // Natural abundance data structure
        const naturalIsotopes = [
            // Hydrogen (Z=1)
            [1, 0, "99.9885%"],
            [1, 1, "0.0115%"],
            // ... [Copy all the naturalIsotopes data]
        ];

        // Known artificially produced isotopes
        const artificialIsotopes = [
            // Hydrogen to Carbon
            [1, 2, "12.32 years"],       // Tritium
            [4, 6, "53.22 days"],        // Beryllium-10
            [6, 8, "5,700 years"],       // Carbon-14

            // Sodium to Phosphorus
            [11, 13, "2.603 years"],     // Sodium-24
            [13, 15, "7.17×10⁵ years"],  // Aluminum-26
            [15, 17, "14.26 days"],      // Phosphorus-32
            [15, 18, "25.34 days"],      // Phosphorus-33

            // Potassium to Iron
            [19, 21, "1.25×10⁹ years"],  // Potassium-40
            [20, 25, "162.7 days"],      // Calcium-45
            [24, 29, "27.7 days"],       // Chromium-53
            [25, 31, "312.3 days"],      // Manganese-54
            [26, 33, "2.737 years"],     // Iron-55
            [26, 34, "44.495 days"],     // Iron-59

            // Cobalt to Zinc
            [27, 32, "271.74 days"],     // Cobalt-57
            [27, 33, "5.27 years"],      // Cobalt-60
            [28, 35, "100.1 years"],     // Nickel-63
            [28, 36, "2.52 hours"],      // Nickel-65
            [29, 35, "12.701 hours"],    // Copper-64
            [30, 35, "243.93 days"],     // Zinc-65

            // Strontium to Molybdenum
            [38, 52, "28.8 years"],      // Strontium-90
            [39, 51, "58.51 days"],      // Yttrium-90
            [40, 53, "64.032 days"],     // Zirconium-93
            [41, 54, "2.03×10⁴ years"],  // Niobium-94
            [42, 57, "2.748×10⁵ years"], // Molybdenum-99

            // Technetium to Iodine
            [43, 56, "211,000 years"],   // Technetium-99
            [43, 57, "6.01 hours"],      // Technetium-99m
            [44, 59, "39.26 days"],      // Ruthenium-103
            [44, 62, "373.59 days"],     // Ruthenium-106
            [47, 63, "7.45 days"],       // Silver-110m
            [48, 65, "453.7 days"],      // Cadmium-109
            [49, 67, "4.41 days"],       // Indium-111
            [50, 73, "115.09 days"],     // Tin-113
            [51, 74, "60.20 days"],      // Antimony-124
            [53, 78, "8.02 days"],       // Iodine-131
            [53, 79, "12.93 days"],      // Iodine-132

            // Xenon to Europium
            [54, 79, "5.243 days"],      // Xenon-133
            [55, 82, "30.17 years"],     // Cesium-137
            [56, 81, "10.551 days"],      // Barium-133
            [57, 83, "40.272 hours"],    // Lanthanum-140
            [58, 83, "32.51 days"],      // Cerium-141
            [61, 84, "2.62 years"],      // Promethium-145
            [63, 89, "13.537 years"],    // Europium-152
            [63, 91, "8.593 years"],     // Europium-154

            // Heavy and Transuranium Elements
            [88, 138, "1600 years"],     // Radium-226
            [89, 138, "21.772 years"],   // Actinium-227
            [91, 140, "32,760 years"],   // Protactinium-231
            [93, 144, "2.144×10⁶ years"],// Neptunium-237
            [94, 145, "24,100 years"],   // Plutonium-239
            [94, 146, "6,563 years"],    // Plutonium-240
            [94, 147, "14.35 years"],    // Plutonium-241
            [95, 146, "432.2 years"],    // Americium-241
            [95, 147, "7,370 years"],    // Americium-243
            [96, 151, "163.2 days"],     // Curium-247
            [96, 152, "348 years"],      // Curium-248
            [97, 150, "1,380 years"],    // Berkelium-249
            [98, 153, "900.5 years"],    // Californium-251
            [98, 154, "2.645 years"],    // Californium-252
            [99, 154, "276 days"]        // Einsteinium-253
        ];

        // Check if it's a natural isotope
        const naturalIsotope = naturalIsotopes.find(iso =>
            iso[0] === protons && iso[1] === neutrons
        );
        if (naturalIsotope) {
            return {
                abundance: naturalIsotope[2],
                halfLife: null,
                decayMode: null
            };
        }

        // Check if it's an artificial isotope
        const artificialIsotope = artificialIsotopes.find(iso =>
            iso[0] === protons && iso[1] === neutrons
        );
        if (artificialIsotope) {
            const decayMode = this.getDecayModes(protons, neutrons);
            return {
                abundance: "Artificially produced",
                halfLife: artificialIsotope[2],
                decayMode: decayMode
            };
        }

        // Check if the isotope is theoretically possible
        const ratio = neutrons / protons;
        if (protons > 118 || ratio < 0.3 || ratio > 3) {
            return {
                abundance: "Theoretically impossible",
                halfLife: null,
                decayMode: null
            };
        }

        // For all other cases
        return {
            abundance: "Not naturally occurring, but theoretically possible",
            halfLife: null,
            decayMode: null
        };
    }

    calculateStability(protons, neutrons) {
        if (protons === 0 || neutrons === 0) return "No nucleus";

        const Z = protons;
        const N = neutrons;

        // Define stable isotope ranges for each element
        const stableIsotopeRanges = [
            [1, 0, 1],      // H
            [2, 1, 2],      // He
            [3, 3, 4],      // Li
            // ... [Copy all stableIsotopeRanges data]
        ];

        // Find the stable range for this element
        const range = stableIsotopeRanges.find(r => r[0] === Z);

        // All elements beyond Bismuth (83) are radioactive
        if (!range || Z > 83) {
            return "Radioactive";
        }

        const [_, minN, maxN] = range;

        // Check if neutron count is within stable range
        if (N >= minN && N <= maxN) {
            return "Stable";
        }

        return "Radioactive";
    }

    calculateBindingEnergy(protons, neutrons) {
        if (protons === 0 || neutrons === 0) return "No nucleus";

        // Constants for the semi-empirical mass formula (in MeV)
        const a_v = 15.75;  // Volume term
        const a_s = 17.80;  // Surface term
        const a_c = 0.711;  // Coulomb term
        const a_a = 23.7;   // Asymmetry term
        const a_p = 11.18;  // Pairing term

        // Mass numbers
        const A = protons + neutrons;  // Total nucleons
        const Z = protons;
        const N = neutrons;

        // Calculate each term
        const volumeTerm = a_v * A;
        const surfaceTerm = -a_s * Math.pow(A, 2 / 3);
        const coulombTerm = -a_c * (Z * (Z - 1)) / Math.pow(A, 1 / 3);
        const asymmetryTerm = -a_a * Math.pow(N - Z, 2) / A;

        // Calculate pairing term
        let pairingTerm = 0;
        if (Z % 2 === 0 && N % 2 === 0) {        // Even-Even nuclei
            pairingTerm = a_p / Math.pow(A, 1 / 2);
        } else if (Z % 2 === 1 && N % 2 === 1) { // Odd-Odd nuclei
            pairingTerm = -a_p / Math.pow(A, 1 / 2);
        }

        // Total binding energy
        const bindingEnergy = volumeTerm + surfaceTerm + coulombTerm + asymmetryTerm + pairingTerm;

        // Binding energy per nucleon
        const bindingEnergyPerNucleon = bindingEnergy / A;

        if (bindingEnergy <= 0) {
            return "Unbound nucleus";
        }

        return `${bindingEnergyPerNucleon.toFixed(3)} MeV/nucleon  |  Total: ${bindingEnergy.toFixed(1)} MeV`;
    }

    getMagneticProperties(protons, electrons) {
        if (protons === 0) return "No nucleus";

        // Calculate electron configuration
        const electronConfig = this.getElectronConfiguration(protons, electrons);

        // Get nuclear magnetic properties
        const nuclearMagnetic = this.getNuclearMagneticProperties(protons, this.particles.neutrons);

        let properties = [];

        if (nuclearMagnetic.moment) {
            properties.push(`Nuclear magnetic moment: ${nuclearMagnetic.moment} μN`);
        }

        const electronMagnetic = this.getElectronicMagneticProperties(electronConfig);

        if (electronMagnetic.behavior) {
            properties.push(electronMagnetic.behavior);
        }

        if (electronMagnetic.susceptibility) {
            properties.push(`χm ≈ ${electronMagnetic.susceptibility}`);
        }

        return properties.length > 0 ? properties.join("  |  ") : "Magnetically inactive";
    }

    getElectronConfiguration(protons, electrons) {
        const subshells = [
            "1s", "2s", "2p", "3s", "3p", "4s", "3d", "4p", "5s", "4d", "5p", "6s",
            "4f", "5d", "6p", "7s", "5f", "6d", "7p"
        ];
        const maxElectrons = {
            s: 2, p: 6, d: 10, f: 14
        };

        let config = new Map();
        let remainingElectrons = Math.min(electrons, protons);

        for (let subshell of subshells) {
            if (remainingElectrons <= 0) break;

            const shell = subshell.charAt(1);
            const max = maxElectrons[shell];
            const electrons = Math.min(remainingElectrons, max);

            config.set(subshell, electrons);
            remainingElectrons -= electrons;
        }

        return config;
    }

    getNuclearMagneticProperties(protons, neutrons) {
        // Nuclear magnetic moments (in nuclear magnetons μN) for selected isotopes
        const magneticMoments = new Map([
            ["1-1", 2.793],    // proton
            ["1-0", 2.793],    // hydrogen-1
            ["1-1", -0.857],   // deuterium
            ["1-2", 2.979],    // tritium
            ["2-1", -0.857],   // helium-3
            ["3-3", 3.256],    // lithium-6
            ["3-4", 3.256],    // lithium-7
            ["5-5", 2.689],    // boron-10
            ["5-6", 2.689],    // boron-11
            ["7-7", -0.402],   // nitrogen-14
            ["7-8", -0.283],   // nitrogen-15
            ["9-10", 2.629],   // fluorine-19
            ["15-16", 1.131],  // phosphorus-31
            ["17-18", 0.821],  // chlorine-35
            ["17-20", 0.683]   // chlorine-37
        ]);

        const key = `${protons}-${neutrons}`;
        const moment = magneticMoments.get(key);

        return { moment: moment };
    }

    getElectronicMagneticProperties(electronConfig) {
        let unpairedElectrons = 0;
        let result = { behavior: "", susceptibility: null };

        // Count unpaired electrons in partially filled subshells
        for (let [subshell, electrons] of electronConfig) {
            const shell = subshell.charAt(1);
            const maxElectrons = { s: 2, p: 6, d: 10, f: 14 }[shell];

            if (electrons < maxElectrons) {
                // Calculate unpaired electrons using Hund's rule
                const pairs = Math.floor(electrons / 2);
                const unpaired = electrons - (pairs * 2);
                unpairedElectrons += unpaired;
            }
        }

        // Determine magnetic behavior
        if (unpairedElectrons === 0) {
            if (this.hasLanthanideContraction(electronConfig)) {
                result.behavior = "Paramagnetic (lanthanide)";
                result.susceptibility = "10⁻³ to 10⁻² cm³/mol";
            } else {
                result.behavior = "Diamagnetic";
                result.susceptibility = "≈ -10⁻⁶ cm³/mol";
            }
        } else {
            if (unpairedElectrons >= 5) {
                result.behavior = "Strongly paramagnetic";
                result.susceptibility = "10⁻² to 10⁻¹ cm³/mol";
            } else {
                result.behavior = "Paramagnetic";
                result.susceptibility = "10⁻⁴ to 10⁻³ cm³/mol";
            }
        }

        return result;
    }

    hasLanthanideContraction(electronConfig) {
        // Check for partially filled 4f subshell
        return electronConfig.has("4f") && electronConfig.get("4f") < 14;
    }

    getReactivity(protons, electrons) {
        if (protons === 0) return "No nucleus";

        const Z = protons;
        const charge = protons - electrons;

        // Calculate electron configuration
        const electronConfig = this.getElectronConfiguration(protons, electrons);

        // Calculate valence electrons and shell filling
        const getValenceInfo = (config) => {
            const shells = ['s', 'p', 'd', 'f'];
            const valenceElectrons = new Map();
            let outerShell = 0;

            for (let [orbital, count] of config) {
                const shellNumber = parseInt(orbital.charAt(0));
                const shellType = orbital.charAt(1);
                outerShell = Math.max(outerShell, shellNumber);

                if (shellNumber === outerShell ||
                    (shellType === 'd' && shellNumber === outerShell - 1)) {
                    valenceElectrons.set(orbital, count);
                }
            }

            return {
                valenceElectrons,
                outerShell,
                totalValence: Array.from(valenceElectrons.values()).reduce((a, b) => a + b, 0)
            };
        };

        const valenceInfo = getValenceInfo(electronConfig);

        // Determine periodic table position
        const getGroupPeriod = (Z, valenceInfo) => {
            const period = valenceInfo.outerShell;
            let group;

            if (Z >= 57 && Z <= 71) return { group: 'lanthanide', period };
            if (Z >= 89 && Z <= 103) return { group: 'actinide', period };

            const hasPartialD = Array.from(electronConfig.entries())
                .some(([orbital, count]) => orbital.includes('d') && count < 10);

            if (hasPartialD) {
                group = 'transition';
            } else {
                const s = valenceInfo.valenceElectrons.get(`${period}s`) || 0;
                const p = valenceInfo.valenceElectrons.get(`${period}p`) || 0;
                group = s + p;
            }

            return { group, period };
        };

        const position = getGroupPeriod(Z, valenceInfo);

        // Calculate electronegativity
        const calculateElectronegativity = (Z, position) => {
            if (Z === 1) return 2.20; // Hydrogen special case

            const { group, period } = position;
            if (group === 'lanthanide' || group === 'actinide') return 1.3;

            const baseEN = 2.0;
            const periodEffect = (period > 2) ? -0.1 * (period - 2) : 0;
            const groupEffect = (typeof group === 'number')
                ? Math.abs(group - 8) * 0.2
                : 0;

            return Math.max(0.7, Math.min(4.0, baseEN + periodEffect - groupEffect));
        };

        const electronegativity = calculateElectronegativity(Z, position);

        // Calculate reactivity factors
        const factors = {
            electronConfiguration: (() => {
                const noble = valenceInfo.totalValence === 0 || valenceInfo.totalValence === 8;
                const halfFilled = Array.from(valenceInfo.valenceElectrons.values())
                    .some(count => count === 2 || count === 5);
                return noble ? 0.1 : (halfFilled ? 0.8 : 1.0);
            })(),

            chargeEffect: Math.abs(charge) * 0.2 + 1,

            electronegativityFactor: (() => {
                const optimalEN = 2.5;
                return 1 + Math.abs(electronegativity - optimalEN) * 0.3;
            })(),

            sizeEffect: 1 + (position.period > 3 ? (0.1 * (position.period - 3)) : 0)
        };

        // Calculate overall reactivity score
        const reactivityScore = Math.min(10,
            (factors.electronConfiguration *
                factors.chargeEffect *
                factors.electronegativityFactor *
                factors.sizeEffect) * 2
        );

        // Generate description
        let description = [];

        const getReactivityLevel = (score) => {
            if (score < 2) return "Extremely unreactive";
            if (score < 4) return "Slightly reactive";
            if (score < 6) return "Moderately reactive";
            if (score < 8) return "Highly reactive";
            return "Extremely reactive";
        };

        description.push(getReactivityLevel(reactivityScore));

        if (valenceInfo.totalValence === 0) {
            description.push("Noble gas configuration");
        } else if (valenceInfo.totalValence < 4) {
            description.push("Tends to lose electrons");
        } else if (valenceInfo.totalValence > 4) {
            description.push("Tends to gain electrons");
        }

        if (electronegativity > 2.8) {
            description.push("Strong oxidizing agent");
        } else if (electronegativity < 1.5) {
            description.push("Strong reducing agent");
        }

        if (charge !== 0) {
            description.push(`Ionic species (${charge > 0 ? '+' : '-'}${Math.abs(charge)})`);
        }

        return description.join(" | ");
    }

    // Placeholder methods that can be implemented later
    getIsotopeApplications(protons, neutrons) {
        return "Data not available";
    }

    getIonizationEnergy(protons, electrons) {
        return "Data not available";
    }

    getSpectralLines(protons, electrons) {
        return "Data not available";
    }

    getNuclearSpin(protons, neutrons) {
        if (protons === 0 || neutrons === 0) return "No nucleus";

        // Magic numbers in nuclear shell model
        const magicNumbers = new Set([2, 8, 20, 28, 50, 82, 126]);

        // Known nuclear spins for specific isotopes
        const knownSpins = new Map([
            // Format: "Z-N": [spin, parity]
            // Hydrogen isotopes
            ["1-0", [1 / 2, "+"]],  // H-1
            ["1-1", [1, "+"]],    // H-2 (Deuterium)
            ["1-2", [1 / 2, "+"]],  // H-3 (Tritium)

            // Helium isotopes
            ["2-1", [1 / 2, "+"]],  // He-3
            ["2-2", [0, "+"]],    // He-4

            // Common isotopes
            ["6-6", [0, "+"]],    // C-12
            ["6-7", [1 / 2, "-"]],  // C-13
            ["7-7", [1, "+"]],    // N-14
            ["8-8", [0, "+"]],    // O-16
            ["9-10", [1 / 2, "+"]], // F-19
            ["11-12", [3 / 2, "+"]],// Na-23
            ["15-16", [1 / 2, "+"]],// P-31
            ["17-18", [3 / 2, "+"]],// Cl-35
            ["19-20", [3 / 2, "+"]],// K-39
            ["20-20", [0, "+"]],  // Ca-40
            ["26-30", [0, "+"]],  // Fe-56
            ["27-32", [7 / 2, "-"]],// Co-59
            ["28-30", [0, "+"]],  // Ni-58
            ["29-34", [3 / 2, "+"]],// Cu-63
            ["30-34", [0, "+"]],  // Zn-64
            ["35-44", [3 / 2, "-"]],// Br-79
            ["47-60", [1 / 2, "+"]],// Ag-107
            ["53-74", [5 / 2, "+"]],// I-127
            ["80-120", [0, "+"]], // Hg-200
            ["82-125", [0, "+"]], // Pb-207
        ]);

        // Check if it's a known isotope
        const key = `${protons}-${neutrons}`;
        const knownSpin = knownSpins.get(key);
        if (knownSpin) {
            const [spin, parity] = knownSpin;
            return this.formatNuclearSpin(spin, parity);
        }

        // Calculate nuclear spin based on nuclear shell model
        const calculateSpin = () => {
            const A = protons + neutrons;

            const isOdd = num => num % 2 === 1;
            const isEven = num => num % 2 === 0;

            const isProtonMagic = magicNumbers.has(protons);
            const isNeutronMagic = magicNumbers.has(neutrons);

            // Rule 1: Even-Even nuclei
            if (isEven(protons) && isEven(neutrons)) {
                return [0, "+"];
            }

            // Rule 2: Odd-Odd nuclei
            if (isOdd(protons) && isOdd(neutrons)) {
                const estimatedSpin = Math.abs(
                    (protons % 2 === 1 ? 0.5 : 0) +
                    (neutrons % 2 === 1 ? 0.5 : 0)
                );
                return [estimatedSpin, isEven(A) ? "+" : "-"];
            }

            // Rule 3: Odd-A nuclei (one unpaired nucleon)
            if ((isOdd(protons) && isEven(neutrons)) || (isEven(protons) && isOdd(neutrons))) {
                const unpaired = isOdd(protons) ? protons : neutrons;
                let shellSpin;

                if (unpaired <= 2) shellSpin = 1 / 2;
                else if (unpaired <= 8) shellSpin = 3 / 2;
                else if (unpaired <= 20) shellSpin = 5 / 2;
                else if (unpaired <= 28) shellSpin = 7 / 2;
                else if (unpaired <= 50) shellSpin = 9 / 2;
                else shellSpin = 11 / 2;

                const parity = unpaired <= 50 ? "+" : "-";

                return [shellSpin, parity];
            }

            return [isEven(A) ? 0 : 1 / 2, "+"];
        };

        const [calculatedSpin, calculatedParity] = calculateSpin();

        const refineSpin = (spin, parity) => {
            const isNearMagic = (num) => {
                return Array.from(magicNumbers).some(magic => Math.abs(magic - num) <= 2);
            };

            if (isNearMagic(protons) || isNearMagic(neutrons)) {
                return [spin, parity];
            }

            return [spin, parity, true];
        };

        const [refinedSpin, refinedParity, uncertain] = refineSpin(calculatedSpin, calculatedParity);

        return this.formatNuclearSpin(refinedSpin, refinedParity, uncertain);
    }

    formatNuclearSpin(spin, parity, uncertain = false) {
        const formatSpinValue = (spin) => {
            if (spin === Math.floor(spin)) return spin.toString();
            if (spin === 0.5) return "½";
            if (spin === 1.5) return "³⁄₂";
            if (spin === 2.5) return "⁵⁄₂";
            if (spin === 3.5) return "⁷⁄₂";
            if (spin === 4.5) return "⁹⁄₂";
            if (spin === 5.5) return "¹¹⁄₂";
            return spin.toString();
        };

        const spinStr = formatSpinValue(spin);
        const uncertaintyStr = uncertain ? " (predicted)" : "";

        return `I = ${spinStr}${parity}${uncertaintyStr}`;
    }

    getDecayModes(protons, neutrons) {
        const Z = protons;
        const N = neutrons;
        const A = Z + N;

        // First check if it's stable using our existing method
        if (this.calculateStability(Z, N) === "Stable") {
            return null;
        }

        // For extreme cases, use the algorithmic approach
        const ratio = N / Z;
        const getOptimalRatio = (Z) => {
            if (Z < 20) return 1.0;
            if (Z < 60) return 1.2 + (Z - 20) * 0.0075;
            return 1.5 + (Z - 60) * 0.00375;
        };
        const optimalRatio = getOptimalRatio(Z);

        // Very neutron-rich or proton-rich isotopes
        if (ratio > optimalRatio + 0.4) {
            return "β⁻ decay with possible neutron emission";
        }
        if (ratio < optimalRatio - 0.4) {
            return Z < 50 ? "β⁺ decay" : "electron capture with possible proton emission";
        }

        // Very heavy elements
        if (Z > 82) {
            return Z > 95 ? "α decay with possible spontaneous fission" : "α decay";
        }

        return "Decay mode uncertain";
    }
}

const AtomsSandbox = () => {
    const canvasRef = useRef(null);
    const simulatorRef = useRef(null);

    // Add state for particle counts
    const [particles, setParticles] = useState({
        protons: 1,
        neutrons: 1,
        electrons: 1
    });

    // Add a callback function to update React state
    const updateParticlesFromSimulator = (newParticles) => {
        setParticles(newParticles);
    };

    useEffect(() => {
        if (canvasRef.current) {
            simulatorRef.current = new ParticleSimulator(canvasRef.current);

            // Pass the callback to the simulator
            simulatorRef.current.onParticlesUpdate = updateParticlesFromSimulator;

            // Initialize event listeners and other setup
            simulatorRef.current.initializeCanvas();
            simulatorRef.current.setupEventListeners();
            simulatorRef.current.setupDropdown();
            simulatorRef.current.updateDisplay();

            // Start animation
            simulatorRef.current.animate(0);

            // Close sidebar by default
            const sidebar = document.querySelector('.sidebar-container');
            if (sidebar) {
                sidebar.classList.remove('show');
            }
        }

        return () => {
            if (simulatorRef.current) {
                simulatorRef.current.cleanup();
            }
        };
    }, []);

    // Handle particle count changes
    const handleParticleChange = (type, value) => {
        // Ensure value is a valid number and within limits
        const numValue = parseInt(value) || 0;
        const maxValues = {
            protons: 118,    // Heaviest known element (Oganesson)
            neutrons: 177,   // Maximum neutrons in known elements
            electrons: 118   // Maximum electrons (matches max protons)
        };

        // Clamp value between 0 and max
        const clampedValue = Math.min(Math.max(0, numValue), maxValues[type]);

        // Update React state
        const newParticles = { ...particles, [type]: clampedValue };
        setParticles(newParticles);

        // Update simulator state
        if (simulatorRef.current) {
            simulatorRef.current.particles = newParticles;
            simulatorRef.current.updateDisplay();
            simulatorRef.current.draw();
        }
    };

    // Add toggle function for sidebar
    const toggleSidebar = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const sidebar = document.querySelector('.sidebar-container');
        if (sidebar) {
            sidebar.classList.toggle('show');
            // Dispatch custom event to notify Sidebar component
            document.dispatchEvent(new Event('sidebarToggle'));
        }
    };

    return (
        <>
            <div className="info-button menu-button">
                <button className="info-toggle" onClick={(e) => toggleSidebar(e)}>
                    <span className="material-icons">menu</span>
                </button>
            </div>

            <div className="info-button info-panel-button">
                <button className="info-toggle" onClick={() => {
                    const infoPanel = document.querySelector('.info-panel');
                    if (infoPanel) {
                        infoPanel.classList.add('show');
                    }
                }}>
                    <span className="material-icons">info</span>
                </button>
            </div>

            <Sidebar />

            <div className="element-selector">
                <div id="elementTitle" className="element-display"></div>
                <div className="dropdown-container">
                    <button className="dropdown-button">▼</button>
                    <div className="dropdown-content">
                        <input type="text" id="elementSearch" placeholder="Search elements..." />
                        <div className="dropdown-list"></div>
                    </div>
                </div>
            </div>

            <div className="info-panel">
                <div className="info-header">
                    <h2>Element Properties</h2>
                    <button className="close-info" onClick={() => {
                        const infoPanel = document.querySelector('.info-panel');
                        if (infoPanel) {
                            infoPanel.classList.remove('show');
                        }
                    }}>×</button>
                </div>
                <div className="info-content">
                    <div className="info-section">
                        <h3>Stability</h3>
                        <p id="infoStability">-</p>
                    </div>
                    <div className="info-section">
                        <h3>Natural Abundance</h3>
                        <p id="infoAbundance">-</p>
                    </div>
                    <div className="info-section" id="halfLifeSection">
                        <h3>Half-Life</h3>
                        <p id="infoHalfLife">-</p>
                    </div>
                    <div className="info-section" id="decayModeSection">
                        <h3>Decay Mode</h3>
                        <p id="infoDecayModes">-</p>
                    </div>
                    <div className="info-section">
                        <h3>Binding Energy</h3>
                        <p id="infoBindingEnergy">-</p>
                    </div>
                    <div className="info-section">
                        <h3>Magnetic Properties</h3>
                        <p id="infoMagnetic">-</p>
                    </div>
                    <div className="info-section">
                        <h3>Chemical Reactivity</h3>
                        <p id="infoReactivity">-</p>
                    </div>
                    <div className="info-section">
                        <h3>Nuclear Spin</h3>
                        <p id="infoNuclearSpin">-</p>
                    </div>
                    <div className="info-section">
                        <h3>Isotope Applications</h3>
                        <p id="infoApplications">-</p>
                    </div>
                    <div className="info-section">
                        <h3>Ionization Energy</h3>
                        <p id="infoIonization">-</p>
                    </div>
                    <div className="info-section">
                        <h3>Spectral Lines</h3>
                        <p id="infoSpectral">-</p>
                    </div>
                </div>
            </div>

            <canvas ref={canvasRef} id="particleCanvas"></canvas>

            <div className="controls-panel">
                {['protons', 'neutrons', 'electrons'].map(type => (
                    <div className="control-group" key={type}>
                        <label>{type.charAt(0).toUpperCase() + type.slice(1)}</label>
                        <div className="number-control">
                            <button
                                className="decrease"
                                onClick={() => handleParticleChange(type, particles[type] - 1)}
                            >
                                −
                            </button>
                            <input
                                type="number"
                                id={`${type}Input`}  // Add ID for simulator to find
                                value={particles[type]}
                                onChange={(e) => handleParticleChange(type, e.target.value)}
                                min="0"
                                max={type === 'neutrons' ? 177 : 118}
                            />
                            <button
                                className="increase"
                                onClick={() => handleParticleChange(type, particles[type] + 1)}
                            >
                                +
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default AtomsSandbox;
