import React, { useState, useEffect } from 'react';
import './Sidebar.css';
import HomeButton from './SidebarComponents/HomeButton';
import SideProfileButton from './SidebarComponents/SideProfileButton';
import PathsButton from './SidebarComponents/PathsButton';
import ProButton from './SidebarComponents/ProButton';
import SideSearchbar from './SidebarComponents/SideSearchbar';

const Sidebar = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleSidebarToggle = () => {
      const sidebar = document.querySelector('.sidebar-container');
      setIsVisible(sidebar.classList.contains('show'));
    };

    document.addEventListener('sidebarToggle', handleSidebarToggle);
    return () => document.removeEventListener('sidebarToggle', handleSidebarToggle);
  }, []);

  const toggleSidebar = () => {
    setIsVisible(!isVisible);
    const sidebar = document.querySelector('.sidebar-container');
    if (sidebar) {
      sidebar.classList.toggle('show');
    }
  };

  return (
    <div className={`sidebar-container ${isVisible ? 'show' : ''}`}>
      <div className="sidebar">
        <div className="sidebar-header">
          <h2>Navigation</h2>
          <button className="collapse-button" onClick={toggleSidebar}>
            ×
          </button>
        </div>
        <div className="sidebar-content">
          <SideSearchbar />
          <div className='sidebar-button-group'>
            <HomeButton />
            <SideProfileButton />
            <PathsButton />
            <ProButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
